export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"642c970f8d8a1a00208a3a96",
				"642c970f8d8a1a00208a3a99",
				"642c99add28fe1002137f37b",
				"642d7e32bf72b300207cd992",
				"642c99f03dedc4001fca4aa0"
			]
		},
		"642c970f8d8a1a00208a3a96": {
			"id": "642c970f8d8a1a00208a3a96",
			"name": "404",
			"pageUrl": "404"
		},
		"642c970f8d8a1a00208a3a99": {
			"id": "642c970f8d8a1a00208a3a99",
			"name": "Home",
			"pageUrl": "index",
			"seo": {
				"title": "Niander - Home"
			},
			"styles": {
				"width": "1280px",
				"min-width": "1280px",
				"max-width": "1280px",
				"margin": "0 auto 0 0px"
			}
		},
		"642c99add28fe1002137f37b": {
			"id": "642c99add28fe1002137f37b",
			"pageUrl": "clinical",
			"name": "Clinical",
			"seo": {
				"title": "Niander - Clinical"
			},
			"styles": {
				"width": "1280px",
				"min-width": "1280px",
				"max-width": "1280px",
				"margin": "0 auto 0 0px"
			}
		},
		"642c99f03dedc4001fca4aa0": {
			"id": "642c99f03dedc4001fca4aa0",
			"pageUrl": "about",
			"name": "About",
			"styles": {
				"width": "1280px",
				"min-width": "1280px",
				"max-width": "1280px",
				"margin": "0 auto 0 0px"
			},
			"seo": {
				"title": "Niander - About"
			}
		},
		"642d7e32bf72b300207cd992": {
			"pageUrl": "technology",
			"id": "642d7e32bf72b300207cd992",
			"name": "Technology",
			"styles": {
				"min-width": "1280px",
				"width": "1280px",
				"max-width": "1280px",
				"margin": "0 auto 0 0px"
			},
			"seo": {
				"title": "Niander - Technology"
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"width": "1280px",
			"min-width": "1280px",
			"max-width": "1280px",
			"margin": "0px auto 0px 0"
		},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"
		}
	}
}