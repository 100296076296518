import React from "react";
import { useOverrides, Override, Menu } from "@quarkly/components";
import { Image, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "1% 0 0 0",
	"position": "sticky",
	"top": 0,
	"bottom": 0,
	"z-index": "999"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "center",
			"flex-direction": "row",
			"md-flex-direction": "column"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21:45:52.259Z",
			"display": "block",
			"width": "20%"
		}
	},
	"menu": {
		"kind": "Menu",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"font": "--base",
			"font-weight": "700",
			"md-flex-direction": "column",
			"md-align-items": "center"
		}
	},
	"menuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration": "none",
			"color": "--dark",
			"padding": "6px 12px"
		}
	},
	"menuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"color": "--primary"
		}
	},
	"menuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"padding": "6px"
		}
	}
};

const NianderHeader = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Image {...override("image")} />
			<Menu {...override("menu")}>
				<Override {...override("menuOverride")} />
				<Override {...override("menuOverride1")} />
				<Override {...override("menuOverride2")} />
			</Menu>
		</Box>
		{children}
	</Section>;
};

Object.assign(NianderHeader, { ...Section,
	defaultProps,
	overrides
});
export default NianderHeader;