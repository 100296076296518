import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Strong, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"clinical"} />
		<Helmet>
			<title>
				Niander - Clinical
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.NianderHeader background="#ffffff" />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
				color="--nianderPurple"
			>
				Pandemic Infection Symptoms and Predictors
			</Text>
		</Section>
		<Box width="80%" margin="0px auto 0px auto">
			<Text margin="0px 0px 3% auto" font="--headline2" text-align="center">
				Background: Symptoms and Predictors
			</Text>
			<Text margin="0px 0px 0px 0px" text-align="center">
				The results of many studies and observations during the pandemic have demonstrated the
relevance of olfactory dysfunction as a suitable biomarker to aid the early identification of
cases of pandemic infection.{" "}
				<br />
				<br />
				It is an easily recognizable symptom, that can also be easily
objectively tested and verified; it occurs at a high prevalence rate and early in the disease
process; it occurs even when other symptoms do not and is a predominant symptom when
few other symptoms are present; its sudden-onset nature is specific to pandemic infection.
			</Text>
		</Box>
		<Image
			src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12:52:06.544Z"
			display="block"
			margin="5% auto 0px auto"
			height="500px"
			srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/graphanosmia-1024x778.png?v=2023-04-05T12%3A52%3A06.544Z&quality=85&w=3200 3200w"
			sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
		/>
		<Section sm-padding="60px 0 60px 0" quarkly-title="Applications">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" display="block" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Box
					justify-content="space-around"
					align-items="center"
					width="100%"
					align-self="center"
					flex-wrap="wrap"
					align-content="center"
					display="flex"
				>
					<Box
						display="block"
						justify-content="flex-start"
						align-items="stretch"
						quarkly-title="Niander"
						margin="auto 0px auto 0px"
					>
						<Image
							src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13:10:00.902Z"
							display="block"
							width="200px"
							height="20px"
							opacity="0"
							srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/lossofsmell.png?v=2023-04-05T13%3A10%3A00.902Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Image
							src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21:45:52.259Z"
							display="block"
							width="200px"
							margin="0px auto 0px auto"
							srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							color="--nianderPurple"
							font="normal 500 32px/2 --fontFamily-sans"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
						>
							Loss of Taste/Smell
						</Text>
						<Text
							color="--nianderPurple"
							font="normal 300 24px/1.5 --fontFamily-sans"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
							margin="-20px 0px 16px 0px"
						>
							Days 1-3
						</Text>
					</Box>
					<Box display="block" justify-content="flex-start" align-items="stretch" quarkly-title="Symptom">
						<Image
							src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13:03:54.561Z"
							display="block"
							height="20px"
							width="200px"
							opacity="0"
							srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/cough.png?v=2023-04-05T13%3A03%3A54.561Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							color="--nianderPurple"
							font="normal 500 28px/2 --fontFamily-sans"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
						>
							Cough
						</Text>
						<Text
							color="--nianderPurple"
							font="--lead"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
							margin="-20px 0px 16px 0px"
						>
							Days 5-6
						</Text>
						<Box display="block" justify-content="flex-start" align-items="stretch" quarkly-title="Symptom">
							<Image
								src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13:03:54.560Z"
								display="block"
								height="20px"
								width="200px"
								opacity="0"
								srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/muscleache.png?v=2023-04-05T13%3A03%3A54.560Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
							<Text
								color="--nianderPurple"
								font="normal 500 28px/2 --fontFamily-sans"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								quarkly-title="Restaurants"
								text-align="center"
							>
								Muscle Aches
							</Text>
							<Text
								color="--nianderPurple"
								font="--lead"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								quarkly-title="Restaurants"
								text-align="center"
								margin="-20px 0px 16px 0px"
							>
								Days 5-6
							</Text>
						</Box>
					</Box>
					<Box display="block" justify-content="flex-start" align-items="stretch" quarkly-title="Symptom">
						<Image
							src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13:03:54.567Z"
							display="block"
							height="20px"
							width="200px"
							opacity="0"
							srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/vomit.png?v=2023-04-05T13%3A03%3A54.567Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							color="--nianderPurple"
							font="normal 500 28px/2 --fontFamily-sans"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
						>
							Vomit or Diarrhea
						</Text>
						<Text
							color="--nianderPurple"
							font="--lead"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							quarkly-title="Restaurants"
							text-align="center"
							margin="-20px 0px 16px 0px"
						>
							Days 5-6
						</Text>
						<Box display="block" justify-content="flex-start" align-items="stretch" quarkly-title="Symptom">
							<Image
								src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13:03:54.559Z"
								display="block"
								height="20px"
								width="200px"
								opacity="0"
								srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/fever.png?v=2023-04-05T13%3A03%3A54.559Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
							<Text
								color="--nianderPurple"
								font="normal 500 28px/2 --fontFamily-sans"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								quarkly-title="Restaurants"
								text-align="center"
							>
								Fever
							</Text>
							<Text
								color="--nianderPurple"
								font="--lead"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								quarkly-title="Restaurants"
								text-align="center"
								margin="-20px 0px 16px 0px"
							>
								Days 5-6
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" align-items="stretch">
			<Box width="80%" margin="0 auto 2% auto">
				<Text margin="0px 0px 3% auto" font="--headline2" text-align="center">
					Background: Rigorous Clinical Data
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px 4%"
				md-grid-template-columns="1fr"
				justify-items="center"
				align-content="space-around"
				justify-content="center"
				position="static"
			>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						High Sensitivity
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Pooled data from clinical trials for olfactory tests shows a sensitivity of 90%
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						High Specificity
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Pooled data from clinical trials for olfactory tests shows a specificity of over 96%
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Registration
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						The test is registered with the FDA
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Multiple Clinical Trials
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Olfactory tests were evaluated in 3 prospective clinical trials in subjects without any specific clinical
suspicion of pandemic infection, performed in various user settings. RT-PCR tests
were used as a comparison method.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Rapid Easy Use
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						The Niander Pandemic Symptom Screening Test is easy to use and can be completed
by anyone in approximately one minute. It can be conducted anywhere that is free
from the presence of strong odors which could mask the test odor.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Early Detection
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Loss of normal olfactory function has been widely demonstrated to be an early
indicator of pandemic infection, occurring prior to many of the other common
symptoms
					</Text>
				</Box>
			</Box>
			<Box
				align-items="center"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				margin="2% auto 0px auto"
				width="30%"
			>
				<Text
					margin="0px 0px 11px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="left"
					text-align="center"
				>
					Multiple Evaluation Criteria
				</Text>
				<Text
					margin="0px 0px 12px 0px"
					color="--greyD3"
					font="--base"
					text-align="center"
					flex="1 0 auto"
				>
					The Niander Pandemic Symptom Screening Test uses loss of olfactory dysfunction in
addition to a multi-parameter questionnaire to optimize the identification of
“infection suspects”
				</Text>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" align-items="stretch">
			<Box width="80%" margin="0 auto 2% auto">
				<Text margin="0px 0px 3% auto" font="--headline2" text-align="center">
					Clinical Data in More Detail
				</Text>
			</Box>
			<Box width="90%" margin="0 auto 2% auto" font="--headline3">
				<Text margin="0px 0px 3% auto" font="--base" text-align="center">
					{" "}Olfactory Symptom Screening Tests were evaluated in 3 prospective trials, in Switzerland and
Germany, involving a total of 465 tests in adult subjects.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Study 1
					</Strong>
					: 50 subjects were tested repeatedly once per day on 8 test days, within a two week
period using Olfactory Symptom Screening Tests. A total of 387 tests were carried out and a total
of 10 subjects were detected as being suspected of a COVID-19 infection. Four (4) of these
10 subjects tested RT-PCR positive. This resulted in a sensitivity of 100% and specificity of
80%.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Study 2
					</Strong>
					: Olfactory Symptom Screening Tests were performed on 150 subjects and sixteen (16) were
suspected of a COVID-19 infection. Five (5) of these 16 subjects tested RT-PCR positive
following same day testing. One (1) further subject tested RT- PCR positive, which
an Olfactory Symptom Screening Test had not detected as being suspected of a COVID-19 infection.
Thus, 5 out of the 6 RT-PCR positive subjects were initially correctly detected as being

suspected of a COVID-19 infection by means of olfactory symptom screening tests. This resulted in a
sensitivity of 83.3% and a specificity of 92.4%.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Study 3
					</Strong>
					: 265 subjects performed Olfactory Symptom Screening Tests. All were also tested using RT-
PCR. All were visitors to a concert in Germany and testing was performed within one day.
264/265 subjects passed Olfactory Symptom Screening Tests and 1 failed. All 265 subjects were RT-
PCR negative. Therefore, the test identified 1 false positive case and had a specificity of
99.6%.
				</Text>
			</Box>
			<Box width="85%" margin="0 auto 2% auto" font="--headline3">
				<Text margin="0px 0px 3% auto" font="--headline3" text-align="center">
					Pooled Analysis of Olfactory Symptom Screening Tests
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--base"
				>
					<Text margin="0px 0px 10px 0px">
						A pooled analysis of the 3 datasets available to date shows that Olfactory Symptom Screening
Tests have a sensitivity of 90.0% and a specificity of 96.04%, well above the requirements
for a screening tool.
					</Text>
					<Text margin="0px 0px 10px 0px">
						The tests identify or “tag” approximately 6% (27/465) people in the total test group
as “suspected” infections. The Positive predictive value (PPV) demonstrates that
there is an approximately 33% probability of these people being infected with
COVID-19.
					</Text>
					<Text margin="0px 0px 0px 0px">
						These results demonstrate that Olfactory Symptom Screening Tests clearly meet the goals
of sensitivity, specificity for a screening test. This information can
be used to inform and reduce risk of infection spread in crowded and close contact
venues, enforce isolation mandates and reduce the burden on more expensive
testing infrastructure.
					</Text>
				</List>
				<Components.QuarklycommunityKitTable
					colsProp="4"
					rowsProp="6"
					margin="4% auto 0px auto"
					display="flex"
					showHeader={false}
					showFooter={false}
					border-width="0px"
				>
					<Override slot="TBody" margin="0px auto 0px auto" />
					<Override slot="Text TBody Row-0 Col-0">
						{" "}
					</Override>
					<Override slot="Text TBody Row-0 Col-1" font="--lead">
						RT-PCR Positive
					</Override>
					<Override slot="Text TBody Row-0 Col-2" font="--lead">
						RT-PCR Negative
					</Override>
					<Override slot="Text TBody Row-0 Col-3" font="--lead" text-align="center">
						Total
					</Override>
					<Override slot="Text TBody Row-1 Col-0" font="--lead">
						Olfactory Test "FAIL" (Positive)
					</Override>
					<Override slot="Text TBody Row-2 Col-0" font="--lead">
						Olfactory Test "PASS" (Negative)
					</Override>
					<Override slot="Text TBody Row-3 Col-0" font="--lead">
						<Strong>
							Total
						</Strong>
					</Override>
					<Override slot="Text TBody Row-4 Col-0" font="--lead">
						Sensitivity
					</Override>
					<Override slot="Text TBody Row-5 Col-0" font="--lead">
						Specificity
					</Override>
					<Override slot="Text TBody Row-1 Col-1" font="--lead" text-align="center">
						9
					</Override>
					<Override slot="Text TBody Row-1 Col-2" font="--lead" text-align="center">
						18
					</Override>
					<Override slot="Text TBody Row-1 Col-3" font="--lead" text-align="center">
						27
					</Override>
					<Override slot="Text TBody Row-2 Col-1" font="--lead" text-align="center">
						1
					</Override>
					<Override slot="Text TBody Row-2 Col-2" font="--lead" text-align="center">
						437
					</Override>
					<Override slot="Text TBody Row-2 Col-3" font="--lead" text-align="center">
						438
					</Override>
					<Override slot="Text TBody Row-3 Col-1" font="--lead" text-align="center">
						10
					</Override>
					<Override slot="Text TBody Row-3 Col-2" font="--lead" text-align="center">
						455
					</Override>
					<Override slot="Text TBody Row-3 Col-3" font="--lead">
						465
					</Override>
					<Override slot="Text TBody Row-4 Col-1" font="--lead">
						{" "}
					</Override>
					<Override slot="Text TBody Row-4 Col-2" font="--lead" text-align="center">
						<Strong>
							90.00%
						</Strong>
					</Override>
					<Override slot="Text TBody Row-4 Col-3" font="--lead">
						{" "}
					</Override>
					<Override slot="Text TBody Row-5 Col-1" font="--lead">
						{" "}
					</Override>
					<Override slot="Text TBody Row-5 Col-2" font="--lead" text-align="center">
						<Strong text-align="center">
							96.04%
						</Strong>
					</Override>
					<Override slot="Text TBody Row-5 Col-3" font="--lead">
						{" "}
					</Override>
					<Override slot="Cell" height="70px" border-color="rgba(228, 232, 236, 0)" text-align="left" />
					<Override slot="Row" height="60px" />
				</Components.QuarklycommunityKitTable>
			</Box>
		</Section>
		<Components.NianderFooter2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"642c970f8d8a1a00208a3a8f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});