import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Niander - Home
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.NianderHeader background="#ffffff" />
		<Image
			src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21:45:52.259Z"
			display="block"
			width="70%"
			margin="5% auto 0px auto"
			text-align="left"
			srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/niander.png?v=2023-04-04T21%3A45%3A52.259Z&quality=85&w=3200 3200w"
			sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
		/>
		<Section sm-padding="60px 0 60px 0" quarkly-title="Intro" padding="5% 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px 8%" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Pandemic Infection Symptom Screening Test
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Quick, easy, accurate for early detection. *
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Break infection chains immediately
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					With an innovative 1-minute pandemic infection symptom screening test that anyone can do anywhere.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="9px sans-serif"
					text-align="left"
					padding="0px 15% 0px 0px"
					color="rgba(0, 0, 0, 0.53)"
				>
					* The sudden loss of smell is one of the key early symptoms of a 
pandemic infection. The ability to identify the scents provided is not a
 guaranty of a negative pandemic infection status.{" \t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\n\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\n\t\t"}
				</Text>
			</Box>
			<Box
				display="grid"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				overflow-x="hidden"
			>
				<Image
					src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09:22:31.504Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="250px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%202023-04-11%20at%2011.21.29.png?v=2023-04-11T09%3A22%3A31.504Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" quarkly-title="Process">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" display="block" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Box
					display="flex"
					justify-content="flex-start"
					align-items="stretch"
					width="80%"
					margin="0px 0px 0px 2%"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--dark"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Five Simple Steps
					</Text>
					<Text
						margin="0px 0px 40px 0px"
						color="--nianderPurple"
						font="--lead"
						lg-text-align="center"
						padding="2% 0px 0px 5%"
					>
						Scan. Scratch. Smell. Select. Result.
					</Text>
				</Box>
				<Box
					justify-content="space-around"
					align-items="stretch"
					width="100%"
					display="flex"
					padding="0px 0px 0px 2%"
				>
					<Text
						color="--nianderPurple"
						font="normal 300 22px/1.5 --fontFamily-sans"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						width="25%"
						align-self="center"
					>
						The{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Niander
						</Strong>
						™ Rapid and Objective Pandemic Infection Symptom Screening Test provides a quick and
easy, objective and cost-effective way to identify suspected infections.
					</Text>
					<Box
						justify-content="space-around"
						align-items="stretch"
						width="70%"
						display="block"
						align-self="center"
					>
						<Box>
							<Text
								color="--dark"
								font="normal 300 18px/1.5 --fontFamily-sans"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								text-align="left"
								padding="0px 0px 0px 5%"
							>
								The user{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
									color="--nianderPurple"
								>
									SCAN
								</Strong>
								s the QR code on the Niander™ test using their phone or internet enabled
device to open the App.
							</Text>
						</Box>
						<Box>
							<Text
								color="--dark"
								font="normal 300 18px/1.5 --fontFamily-sans"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								text-align="left"
								padding="0px 0px 0px 5%"
							>
								They then{" "}
								<Strong
									color="--nianderPurple"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									SCRATCH
								</Strong>
								{" "}the patch on the teest to release the odor and{"\n"}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
									color="--nianderPurple"
								>
									SMELL
								</Strong>
								{" "}the scent. The user then{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
									color="--nianderPurple"
								>
									SELECT
								</Strong>
								s the corresponding odor from the option list
provided in the App and completes the survey questions.
							</Text>
						</Box>
						<Box>
							<Text
								color="--dark"
								font="normal 300 18px/1.5 --fontFamily-sans"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								text-align="left"
								padding="0px 0px 0px 5%"
							>
								The{" "}
								<Strong color="--nianderPurple">
									RESULT
								</Strong>
								{" "}of the test is provided
on the App in the form of a digital pass.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Image
				src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09:24:56.645Z"
				object-fit="cover"
				border-radius="16px"
				hover-transform="translateY(-10px)"
				sm-min-height="100vw"
				width="100%"
				margin="0px auto 0px auto"
				srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2011-24-47.png?v=2023-04-11T09%3A24%3A56.645Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Components.NianderFooter2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"642c970f8d8a1a00208a3a8f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});