import React from "react";
import theme from "theme";
import { Theme, Text, Section, List, Box, Image, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdExtension } from "react-icons/md";
import { FaQrcode } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"technology"} />
		<Helmet>
			<title>
				Niander - Technology
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.NianderHeader background="#ffffff" />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
				color="--nianderPurple"
			>
				Technology
			</Text>
		</Section>
		<Box width="80%" margin="0px auto 0px auto">
			<Text margin="0px 0px 3% auto" font="--headline2" text-align="center">
				Smell Dysfunction during Pandemic Infection
			</Text>
			<Text margin="0px 0px 0px 0px" text-align="center">
				Sense of smell is an ancient and vital perception, with the human olfactory system capable
of discriminating among thousands of airborne chemicals at concentrations below the
detection limits of the most complex analytical systems (1). However, its importance is only
appreciated when it is lost – as highlighted by the recent pandemic infection. Loss of sense of
smell has been demonstrated to be of diagnostic value in this pandemic infection (2, 3).
			</Text>
			<List
				margin="10px auto 0px auto"
				padding="20px 0px 0px 20px"
				list-style-type="none"
				as="ul"
				width="70%"
			>
				<Text margin="0px 0px 0px 0px" font="normal 300 10px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					(1) Doty RL, ed. Handbook of olfaction and gustation. Wiley, 2015
doi:10.1002/9781118971758
				</Text>
				<Text margin="0px 0px 0px 0px" font="normal 300 10px/1.5 --fontFamily-sans">
					(2) American Academy of Otolaryngology–Head and Neck Surgery Foundation . ENT
Health. Hyposmia and anosmia. Alexandria, VA: American Academy of
Otolaryngology–Head and Neck Surgery Foundation; 2020.
https://www.enthealth.org/conditions/hyposmia-and-anosmia/.
				</Text>
				<Text margin="0px 0px 0px 0px" font="normal 300 10px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					(3) Hopkins C, Kumar N. Loss of sense of smell as marker of COVID‐19 infection 2020.
London, UK: ENT UK, The Royal College of Surgeons of England; 2020.
https://www.entuk.org/loss-sense-smell-marker-covid-19-infection-0.
				</Text>
			</List>
		</Box>
		<Box width="80%" margin="6% auto 0px auto">
			<Text margin="0px 0px 3% auto" font="--headline2" text-align="center">
				Smell Intensities
			</Text>
			<Text margin="0px 0px 0px 0px" text-align="center">
				The odor recognition threshold is the minimum concentration of a substance at which a
majority of test subjects can detect and identify the characteristic odor of a substance.
				<br />
				<br />
				{"\n"}The smells used in the Niander Pandemic Symptom Screening Test are selected based on
multiple criteria including detection and cognition levels based on established scientific
literature, cultural familiarity with the smells and appropriateness for the infection. The smell portfolio is continuously monitored and optimized for maximum effectiveness.{"\n"}
				<br />
				<br />
				By operating in the “optimized cognition” zone, the smells in the Niander Pandemic
Symptom Screening Test are optimized for overall test sensitivity.
			</Text>
		</Box>
		<Image
			src="https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12:11:55.382Z"
			display="block"
			margin="0px auto 0px auto"
			width="80%"
			padding="0px 0px 0px 2%"
			height="400px"
			srcSet="https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2014-11-46.png?v=2023-04-11T12%3A11%3A55.382Z&quality=85&w=3200 3200w"
			sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
		/>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" width="75%" />
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Box display="flex" justify-content="flex-start">
						<Icon
							category="md"
							icon={MdExtension}
							margin="0px 0px 16px 0px"
							color="--dark"
							size="48px"
							padding="6px 6px 6px 6px"
							width="80px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="normal 500 28px/1.9 --fontFamily-sans" lg-text-align="left">
							Structure
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Niander uses complex analytical processing to determine if the user is suspected of
infection, based on multiple parameters in addition to loss of sense of smell.{"\n"}
						<br />
						<br />
						Sophisticated encryption methods and authentication systems are employed to assure
ticket authenticity. The electronic pass is encrypted and checked via Guard Apps for
authenticity and validity.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Box display="flex" justify-content="flex-start">
						<Icon
							category="fa"
							icon={FaQrcode}
							margin="0px 0px 16px 0px"
							color="--dark"
							size="48px"
							padding="6px 6px 6px 6px"
							width="80px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="normal 500 28px/1.9 --fontFamily-sans" lg-text-align="left">
							QR Codes
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						The Niander QR Code is unique to each individual test and is located inside the sealed ticket.{"\n"}
						<br />
						<br />
						The Niander Symptom Screening App checks the ticket for authenticity and previous use at
the start of the test.
						<br />
						<br />
						{" "}A previously scanned QR code cannot be used again.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Box display="flex" justify-content="flex-start" align-self="auto" order="-1">
						<Icon
							category="io"
							icon={IoMdAnalytics}
							margin="0px 0px 16px 0px"
							color="--dark"
							size="48px"
							padding="6px 6px 6px 6px"
							width="80px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="normal 500 28px/1.9 --fontFamily-sans" lg-text-align="left">
							Data Analysis
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Data collected and stored when using the niander Symptom Screening App is HIPPA
compliant. The outcomes of all tests are recorded in the Niander database, stored as
anonymized data.{"\n"}
						<br />
						<br />
						Niander tickets are delivered in random smell order to customers, but each Niander ticket
has a unique serial number by which it is tracked.{"\n"}
						<br />
						<br />
						Data reports and statistical analysis can be provided for all institutions and customers at
multiple levels. A premium data analytics service is available.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.NianderFooter2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"642c970f8d8a1a00208a3a8f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});