import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Niander - About
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.NianderHeader background="#ffffff" />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0" quarkly-title="NianderTeam">
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--nianderPurple"
			>
				The Niander™ Executive Board
			</Text>
			<Box display="flex" margin="60px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="40%">
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Maie Gall
					</Text>
					<Text margin="10px 0px 0px 0px" font="--headline3">
						Chief Executive Officer
						<br />
						Varanah AG{" "}
						<br />
						Switzerland
					</Text>
					<Text margin="3% auto 0px auto" width="80%" />
				</Box>
				<Box padding="10px" width="40%">
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Kevin Brown
					</Text>
					<Text margin="10px 0px 0px 0px" font="--headline3">
						CEO and President
						<br />
						{" "}Niander LLC.
						<br />
						United States of America
					</Text>
					<Text margin="2% auto 0px auto" width="80%" />
				</Box>
			</Box>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="40%" quarkly-title="Contact Us">
					<Text margin="0px 0px 0px 0px" font="--headline2" quarkly-title="Text" color="--nianderPurple">
						Contact Us
					</Text>
					<Text margin="2% auto 0px auto" width="80%" lg-font="25px sans-serif">
						Niander LLC
						<br />
						{"\n"}1314 East Las Olas Blvd
						<br />
						{"\n"}Suite 1220
						<br />
						{"\n"}Fort Lauderdale
						<br />
						{"\n"}Florida 33301
					</Text>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" sm-padding="40px 0" quarkly-title="Policy">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Data Protection/Privacy Policy
			</Text>
			<Box
				display="block"
				margin="40px 0 20px 0"
				justify-content="space-around"
				sm-flex-direction="column"
				quarkly-title="ProtectionGlance"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3">
					Data Protection at a Glance
				</Text>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						General Information
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The following information provides a simple overview of what happens to your personal
data when you visit our website. Personal data are all data with which you can be personally
identified. Detailed information on the subject of data protection can be found in our data
protection declaration listed below this text.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Data Collection on our Website
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The data processing on this website is carried out by the website operator. You can find
their contact details in the imprint of this website.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						How your data is collected
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Your data is collected when you communicate it to us, for example, the data that you enter
in a contact form. Other data are automatically recorded by our IT systems when you visit
the website. This is mainly technical data (e.g. internet browser, operating system or time
the page was viewed). This data is collected automatically as soon as you enter the website.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Your rights with regard to your data
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						You have the right to receive information about the origin, recipient and purpose of your
stored personal data free of charge at any time. You also have the right to request the
correction, blocking or deletion of this data. You can contact us at any time at the address
given in the legal notice if you have any further questions on the subject of data protection.
You also have the right to lodge a complaint with the competent supervisory authority.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Analysis Tools and Third-party Tools
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						When you visit the website, your surfing behavior can be statistically evaluated. This is
mainly done with cookies and so-called analysis programs. Surfing behavior is usually
analyzed anonymously; it cannot be traced back to you. You can object to this analysis or
prevent it by not using certain tools. Detailed information on this can be found in the
following data protection declaration.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						General Information and Mandatory Information
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The operators of this website take the protection of your personal data very seriously. Your
personal data is treated confidentially and in accordance with the statutory data protection
regulations and this data protection declaration.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						When you use this website, various personal data are collected. Personal data are data with
which you can be personally identified. This data protection declaration explains which data
is collected and what it is used for. It also explains how and for what purpose this is done.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Please note that data transmission over the Internet (e.g. when communicating by e-mail)
can have security gaps. A complete protection of the data against access by third parties is
not possible.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The responsible body for data processing on this website is:
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Strong>
							Niander LLC
						</Strong>
						<br />
						1314 East Las Olas Blvd
						<br />
						{"\n"}Suite 1220
						<br />
						{"\n"}Fort Lauderdale
						<br />
						{"\n"}Florida 33301
						<br />
						{"\n"}Telephone: ??
						<br />
						{"\n"}E-Mail: info@niander.com
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The responsible body is the natural or legal person who, alone or jointly with others, decides
on the purposes and means of processing personal data (e.g. names, email addresses, etc.).
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Revocation of your consent to data processing
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Many data processing operations are only possible with your express consent. You can
revoke your consent at any time. An informal e-mail to us is sufficient. The legality of the
data processing carried out before the revocation remains unaffected by the revocation.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Right of appeal to the competent supervisory authority
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						In the event of violations of data protection law, the person concerned has the right to
lodge a complaint with the competent supervisory authority. The competent supervisory
authority for data protection issues is the state data protection officer of the federal state in
which our company is based. A list of data protection officers and their contact details can
be found at the following link:{"\n"}
						<br />
						<br />
						https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Right to data portability
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						You have the right to have data that we process automatically on the basis of your consent
or in fulfillment of a contract handed over to you or to a third party in a common, machine-
readable format. If you request the direct transfer of the data to another person
responsible, this will only be done if it is technically feasible.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						SSL/TLS Encryption
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						For security reasons and to protect the transmission of confidential content, such as orders
or inquiries that you send to us as the website operator, this site uses an SSL or TLS
encryption. You can recognize an encrypted connection by the fact that the address line of
the browser changes from “http: //” to “https: //” and by the lock symbol in your browser
line.{"\n"}
						<br />
						<br />
						If the SSL or TLS encryption is activated, the data that you transmit to us cannot be read by
third parties.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Information, Blocking, Deletion
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Within the framework of the applicable legal provisions, you have the right to free
information about your stored personal data, their origin and recipient and the purpose of
the data processing and, if necessary, a right to correct, block or delete this data. You can
contact us at any time at the address given in the legal notice if you have any further
questions on the subject of personal data.
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				margin="40px 0 20px 0"
				justify-content="space-around"
				sm-flex-direction="column"
				quarkly-title="DataCollection"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3">
					Data Collection on our Website
				</Text>
				<Box width="90%" margin="0px auto 2% auto" quarkly-title="DataCollection">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Cookies
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Some of the internet pages use so-called cookies. Cookies do not cause any damage to your
computer and do not contain viruses. Cookies serve to make our offer more user-friendly,
more effective and safer. Cookies are small text files that are stored on your computer and
saved by your browser.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Most of the cookies we use are so-called “session cookies”. They are automatically deleted
after your visit. Other cookies remain stored on your device until you delete them. These
cookies enable us to recognize your browser the next time you visit.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						You can set your browser so that you are informed about the setting of cookies and only
allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in
general, and activate the automatic deletion of cookies when you close the browser. If
cookies are deactivated, the functionality of this website may be restricted.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Cookies that are required to carry out the electronic communication process or to provide
certain functions you require (e.g., shopping cart function) are stored on the basis of Art. 6
DSGVO. The website operator has a legitimate interest in the storage of cookies for the
technically error-free and optimized provision of its services. If other cookies (e.g., cookies
for analyzing your surfing behavior) are stored, these will be treated separately in this data
protection declaration.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto" quarkly-title="DataTransmission">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Data transmission upon conclusion of contract for services and digital content
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						We only transmit personal data to third parties if this is necessary in the context of the
execution of the contract, for example to the bank commissioned with the payment
processing.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						A further transmission of the data does not take place or only if you have expressly
consented to the transmission. Your data will not be passed on to third parties without your
express consent, for example for advertising purposes.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The basis for data processing is Art. 6 para. DSGVO, which permits the processing of data for
the performance of a contract or pre-contractual measures.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto" quarkly-title="AnalysisTools">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Analysis Tools and Advertising
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em>
							Google Analytics
						</Em>
						<br />
						<br />
						{"\n"}This website uses functions of the web analysis service Google Analytics. The provider is
Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Google Analytics uses so-called “cookies”. These are text files that are stored on your
computer and that enable an analysis of your use of the website. The information generated
by the cookie about your use of this website is usually transmitted to a Google server in the
USA and stored there.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Google Analytics cookies are stored on the basis of Art. 6 para. 1 lit. f GDPR. The website
operator has a legitimate interest in analyzing user behavior in order to optimize both its
website and its advertising.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em>
							IP Anonymization
						</Em>
						<br />
						<br />
						{"\n"}We have activated the IP anonymization function on this website. As a result, your IP
address will be shortened by Google within member states of the European Union or in
other contracting states of the Agreement on the European Economic Area before
transmission to the USA. Only in exceptional cases will the full IP address be transmitted to
a Google server in the USA and shortened there. On behalf of the operator of this website,
Google will use this information to evaluate your use of the website, to compile reports on
website activity and to provide other services related to website activity and internet usage
to the website operator. The IP address transmitted by your browser as part of Google
Analytics will not be merged with other Google data.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em>
							Browser Plugin
						</Em>
						<br />
						<br />
						You can prevent the storage of cookies by setting your browser software; accordingly,
however, we would like to point out that in this case you may not be able to use all the
functions of this website to their full extent. You can also prevent Google from collecting the
data generated by the cookie and relating to your use of the website (including your IP
address) and from processing this data by Google by downloading and installing the browser
plug-in available under the following link: https://tools.google.com/dlpage/gaoptout?hl=de.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em>
							Objection to Data Collection
						</Em>
						<br />
						<br />
						You can prevent the collection of your data by Google Analytics by clicking on the following
link. An opt-out cookie will be set to prevent the collection of your data on future visits to
this website: Disable Google Analytics.
						<br />
						<br />
						<br />
						More information on the handling of user data by Google Analytics can be found in Google’s
privacy policy: https://support.google.com/analytics/answer/6004245?hl=de
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em>
							Demographic characteristics of Google Analytics
						</Em>
						<br />
						<br />
						{" "}This website uses the “demographic characteristics” function of Google Analytics. This
allows reports to be created that contain statements about the age, gender and interests of
the site visitors. This data comes from interest-based advertising from Google as well as
from visitor data from third-party providers. This data cannot be assigned to a specific
person. You can deactivate this function at any time via the ad settings in your Google
account or generally prohibit the collection of your data by Google Analytics as described in
the section “Objection to data collection”
					</Text>
					<Text width="80%" margin="10px auto 16px auto">
						<Em>
							Google Analytics Remarketing
						</Em>
						<br />
						<br />
						Our websites use the functions of Google Analytics Remarketing in conjunction with the
cross-device functions of Google AdWords and Google DoubleClick. The provider is Google
Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.{"\n"}
						<br />
						<br />
						This feature makes it possible to link the advertising target groups created with Google
Analytics Remarketing with the cross-device functions of Google AdWords and Google
DoubleClick. In this way, interest-based, personalized advertising messages that have been
adapted to you depending on your previous usage and surfing behavior on one device (e.g.,
mobile phone) can also be displayed on another of your devices (e.g. tablet or PC).{"\n"}
						<br />
						<br />
						If you have given your consent, Google will link your web and app browsing history to your
Google Account for this purpose. In this way, the same personalized advertising messages
can be displayed on every device on which you log in with your Google Account.
						<br />
						<br />
						To support this feature, Google Analytics collects Google-authenticated IDs from users,
which are temporarily linked to our Google Analytics data to define and create audiences for
cross-device ad advertising.
						<br />
						<br />
						{"\n"}You can permanently object to cross-device remarketing/targeting by deactivating
personalized advertising in your Google Account; follow this link:
https://www.google.com/settings/ads/onweb/.
						<br />
						<br />
						{"\n"}The summary of the collected data in your Google Account takes place exclusively on the
basis of your consent, which you can give or revoke with Google (Art. 6 para. 1 lit. a GDPR).
In the case of data collection processes that are not merged into your Google Account (e.g.,
because you do not have a Google Account or have objected to the merger), the collection
of the data is based on Art. 6 para. 1 lit. f GDPR. The legitimate interest arises from the fact
that the website operator has an interest in the anonymous analysis of website visitors for
advertising purposes.
						<br />
						<br />
						{"\n"}Further information and the privacy policy can be found in Google’s privacy policy at:
https://www.google.com/policies/technologies/ads/.
					</Text>
					<Text width="80%" margin="10px auto 16px auto">
						<Em>
							Google AdWords and Google Conversion Tracking
						</Em>
						<br />
						<br />
						This website uses Google AdWords. AdWords is an online advertising program of Google
Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).
						<br />
						<br />
						As part of Google AdWords, we use so-called conversion tracking. When you click on an ad
placed by Google, a cookie is set for conversion tracking. Cookies are small text files that the
Internet browser stores on the user’s computer. These cookies lose their validity after 30
days and are not used to personally identify users. If the user visits certain pages of this
website and the cookie has not yet expired, Google and we can recognize that the user
clicked on the ad and was redirected to this page.
						<br />
						<br />
						Each Google AdWords customer receives a different cookie. The cookies cannot be tracked
through the websites of AdWords customers. The information collected using the
conversion cookie is used to create conversion statistics for AdWords customers who have

opted for conversion tracking. Customers learn the total number of users who clicked on
their ad and were redirected to a page with a conversion tracking tag. However, they do not
receive any information that personally identifies users. If you do not wish to participate in
tracking, you can object to this use by easily deactivating the Google Conversion Tracking
cookie via your Internet browser under User Settings. They will then not be included in the
conversion tracking statistics.
						<br />
						<br />
						The storage of “conversion cookies” takes place on the basis of Art. 6 para. 1 lit. f GDPR. The
website operator has a legitimate interest in analyzing user behavior in order to optimize
both its website and its advertising.
						<br />
						<br />
						More information about Google AdWords and Google Conversion Tracking can be found in
Google’s privacy policy: https://www.google.de/policies/privacy/.
						<br />
						<br />
						You can set your browser so that you are informed about the setting of cookies and allow
cookies only in individual cases, exclude the acceptance of cookies for certain cases or in
general and activate the automatic deletion of cookies when closing the browser. Disabling
cookies may limit the functionality of this website.
					</Text>
					<Text width="80%" margin="10px auto 16px auto">
						<Em>
							Facebook Pixel
						</Em>
						<br />
						<br />
						Our website uses the visitor action pixel of Facebook, Facebook Inc., 1601 S. California Ave,
Palo Alto, CA 94304, USA (“Facebook”) for conversion measurement.
						<br />
						<br />
						In this way, the behavior of site visitors can be tracked after they have been redirected to
the provider’s website by clicking on a Facebook advertisement. As a result, the
effectiveness of Facebook ads can be evaluated for statistical and market research purposes
and future advertising measures can be optimized.
						<br />
						<br />
						The data collected is anonymous for us as the operator of this website, we cannot draw any
conclusions about the identity of the users. However, the data is stored and processed by
Facebook so that a connection to the respective user profile is possible and Facebook can
use the data for its own advertising purposes, in accordance with the Facebook Data Use
Policy. This allows Facebook to enable the placement of advertisements on Facebook pages
as well as outside of Facebook. This use of the data cannot be influenced by us as the site
operator.
						<br />
						<br />
						In Facebook’s privacy policy you will find further information on the protection of your
privacy: https://www.facebook.com/about/privacy/.
						<br />
						<br />
						You can also disable the “Custom Audiences” remarketing feature in the Ads Settings
section under
https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen. To do
this, you must be logged in to Facebook.
						<br />
						<br />
						If you do not have a Facebook account, you can deactivate usage-based advertising from
Facebook on the website of the European Interactive Digital Advertising Alliance:
http://www.youronlinechoices.com/de/praferenzmanagement/.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto" quarkly-title="PluginsTools">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Plugins and Integrations
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Youtube
						</Em>
						<br />
						<br />
						Our website uses plugins from the YouTube page operated by Google. The operator of the
pages is YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						When you visit one of our pages equipped with a YouTube plugin, a connection to
YouTube’s servers is established. The YouTube server is informed which of our pages you
have visited.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						If you are logged in to your YouTube account, you enable YouTube to assign your surfing
behavior directly to your personal profile. You can prevent this by logging out of your
YouTube account.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The use of YouTube is in the interest of an appealing presentation of our online offers. This
constitutes a legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Further information on the handling of user data can be found in YouTube’s privacy policy
at: https://www.google.de/intl/de/policies/privacy.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Google Web Fonts
						</Em>
						<br />
						<br />
						This site uses so-called web fonts, which are provided by Google, for the uniform
presentation of fonts. When you call up a page, your browser loads the required web fonts
into your browser cache in order to display texts and fonts correctly.
						<br />
						<br />
						For this purpose, the browser you are using must connect to Google’s servers. As a result,
Google becomes aware that our website has been accessed via your IP address. The use of
Google Web Fonts is in the interest of a uniform and appealing presentation of our online
offers. This constitutes a legitimate interest within the meaning of Art. 6. DSGVO.
						<br />
						<br />
						If your browser does not support web fonts, a standard font will be used by your computer.
For more information about Google Web Fonts, see
https://developers.google.com/fonts/faq and Google’s Privacy Policy:
https://www.google.com/policies/privacy/.
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Google Maps
						</Em>
						<br />
						<br />
						This site uses the map service Google Maps via an API. The provider is Google Inc., 1600
Amphitheatre Parkway, Mountain View, CA 94043, USA.
						<br />
						<br />
						To use the functions of Google Maps, it is necessary to save your IP address. This
information is usually transmitted to a Google server in the USA and stored there. The
provider of this site has no influence on this data transfer.
						<br />
						<br />
						The use of Google Maps is in the interest of an appealing presentation of our online offers
and an easy findability of the places indicated by us on the website. This constitutes a
legitimate interest within the meaning of Art. 6 para. DSGVO.
						<br />
						<br />
						More information on the handling of user data can be found in Google’s privacy policy:
https://www.google.de/intl/de/policies/privacy/
					</Text>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" sm-padding="40px 0" quarkly-title="T&Cs">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Terms and Conditions
			</Text>
			<Box
				display="block"
				margin="40px 0 20px 0"
				justify-content="space-around"
				sm-flex-direction="column"
				quarkly-title="ProtectionGlance"
			>
				<Text width="80%" margin="0px auto 16px auto">
					These general sales terms and conditions (GSTCs) apply to all products (Products) provided
by Niander LLC or any of its affiliates (Corowell), unless explicitly agreed otherwise. No
general terms and conditions of any buyer (Buyer) or similar conditions of purchase shall
apply to the sale by Niander and purchase by Buyer of any Products and such shall sale and
purchase be solely governed by these GSTCs and any other explicitly agreed terms between
Niander and Buyer. Any reference to Buyer’s general purchasing terms and conditions or
similar conditions of purchase shall not be valid and be fully disregarded by Buyer and
Niander.
				</Text>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Order Placement
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						By placing the order, the Buyer accepts these GSTCs. Orders placed orally, in writing or via
remote data transmission only become legally binding if they have been confirmed by
Niander in writing (including via email), or if Niander has sent the Products. Special requests,
e.g., for external packaging, shipping, and quality, must be repeated in every order. Orders
from private individuals cannot be accepted. Non-stock items procured on behalf of
customers are excluded from exchange or return. Orders accepted by Niander cannot be
cancelled by Buyer thereafter.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Delivery
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Delivery of the Products is EXW (Ex Works, Incoterms© 2020) at the location designated by
Niander, unless different delivery terms are agreed between Buyer and Niander or
mandated by the producer of a Product (as will be indicated on the order confirmation).
Buyer shall be obliged to fully cooperate in the delivery of the Products to be delivered by
Niander under any order and any instructions from Niander in that regard. Niander chooses
the packaging of the Products. Additional costs that arise due to special requests for
packaging or handling (e.g., express orders) accepted by Niander will be invoiced separately.
Buyer shall accept the delivery of the Products by taking possession thereof at delivery.
Upon Niander´s request, Buyer shall execute an acceptance certificate for each delivery and
ensure that the person attending the delivery upon Buyer´s behalf is authorized to
represent Buyer in this regard. If Niander is prevented from fulfilling its obligation due to
unforeseeable circumstances, the delivery period will be extended appropriately if the
delivery or service is reasonably affected by such. Unforeseeable circumstances include, for
example, operational disruptions, labour strikes, and events qualifying as force majeure
under applicable law, regardless of whether they occurred in our factory or at our suppliers.
If the aforementioned circumstances make delivery or service impossible for Niander, it
shall be released from its obligations under any then pending order. Niander may suspend
its delivery obligation if and for as long as the buyer is in arrears with a payment or in
breach of any of its other obligations hereunder or under any order. Niander reserves the
right to adjust order quantities accordingly if these do not correspond to the unit of quantity
of standard packaging or if such are not available at Niander at all or in time. Delivery dates
agreed are indicative dates and not fatal. Niander reserves the right to deliver in partial
deliveries, whereby all partial deliveries are charged individually and are due for payment at
the time they are due according to the invoice, regardless of further deliveries. A delay in
delivery does not release Buyer from his obligation to accept the remaining deliveries.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Retention of Title
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						All Products delivered by Niander under any order remain Niander’s property until Buyer
has paid all of his liabilities in respect thereof in full. Niander is entitled to have its retention
of title registered at Buyer’s place of residence in any public register kept by any applicable
authority as Niander deems useful. Until Buyer pays the respective invoices for Products in
full, Buyer shall ensure that the respective Products regarding which it has not yet fulfilled
its payments obligations are kept separately from other Products and that such are clearly
identifiable as bearing Niander’s retention of title. Niander may enter Buyer´s premises
without prior notice during business hours to (i) inspect any Products compliant about by
Buyer, (ii) inspect Buyer’s compliance with these GSTCs, and/or (iii) repossess any Products
in respect of which Niander can claim a retention of title in the event Buyer has breached
any of its obligations hereunder.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Prices and Taxes
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The prices of Products as listed by Niander are indicative sales prices without sales tax, as
well as without all other fees, duties, and taxes, based on EXW (Ex Works, Incoterms©
2020) delivery as stated above. The prices of the Products (Prices) are not binding upon
Niander and may be varied at its sole discretion before any order is accepted by Niander.

Niander provides a Manufacturer Suggested Retail Price (MSRP) for its products. Buyer
agrees to uphold this MSRP to maintain value perception of the Products in the market.
Failure to do so could lead to withdrawal of access to the Niander infrastructure needed to
operate a Product, at sole discretion of Niander.
						<br />
						<br />
						Niander shall be entitled to increase the Prices should one or several of the following
circumstances occur after the conclusion of the order: (i) rise in the costs of materials, semi-
finished products or services required for executing the contract, (ii) rise in the cost of
forwarding, wages, employers’ contributions to social insurances, or costs of other
employment conditions, (iii) the introduction of new and the increase in existing
government levies on raw materials, energy or residues, (iv) substantial changes in currency
rates or, (v) generally speaking, other circumstances that are comparable with the above or
have a similar cost increasing effect for Niander. The calculation is made in the currency of
the prices stated in the respective order. Any value added taxes, sales taxes, withholding
taxes, charges, duties, levies or similar or other taxes applicable to any order (Taxes), the
import or export of any of the Products, the Prices, or otherwise resulting from any order,
save for taxes imposed on Niander regarding its income, shall for the account of and be
payable by Buyer. Buyer shall indemnify Niander against any and all such Taxes and increase
any amount it needs to pay to Niander as to ensure that Niander receives the respective
Price set forth on the invoice net of any such Taxes.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Terms of Payment
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Payment obligations are due upon order without any deduction, set off of suspension being
applied to such and Buyer waives any and all right it may have in this regard to the fullest
extent permissible under applicable law. The payment obligation is only fulfilled when the
invoice amount has been paid into a Niander´s designated bank account. If the respective
payment is not received by Niander in time, Niander is entitled to charge late payment
interest in respect thereof at a rate equal to one (1.0%) compounded per month or the
applicable maximum statutory late payment interest rate if such is lower. A discount is
generally not granted, even if prepayment or payment is made before the final due date
thereof. Niander can request advance payment or guarantees regarding any individual
order, even if such was not requested previously by Niander, at the time the order is
concluded. Niander can also request advance payment or prepayments at any time
thereafter if the payment deadline is exceeded or there are doubts about the customer’s
solvency. In such events, Niander may also revoke payment terms that have been granted.
Should individual invoice items be disputed, this does not release Buyer from its obligation
to settle all undisputed items within the payment terms stated on or applicable to the
invoice.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Complaints, Returns, Warranty and Liability
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Immediately after receipt of the Products, Buyer must check whether the quality and
quantity correspond to the respective order. Any damage must be recorded, including by
photo evidence and samples. Defects that can be identified during the proper inspection of
the Products and in the case of deliveries of Products or quantities other than those ordered
must be complained about within seven (7) calendar days of receipt of the Products. If
Buyer fails to complain in good time, the goods shall be deemed accepted and complying
with the order in full in terms of quality and quantity. Complaints do not release Buyer from

the obligation to pay the invoices in full. Products complained about may only be returned
to Niander with Niander´s express written consent, which consent shall not entail an
acceptance by Niander of the respective complaint. Niander reserves the right to investigate
the respective complaint (or have such conducted by a third party) and does not accept
such unless and until Niander accepts such compliant in writing (including via email).
Products that are not in stock cannot be exchanged or returned. Used or damaged Products
as well as Products without undamaged original packaging cannot be exchanged or
returned. For returns and cancellations of Products in unusually large order quantities, the
return conditions of the respective manufacturer plus any incurring activities apply. If Buyer
orders incorrectly, 25% of the value of the Products will be charged as re-storage and
processing fees. In the case of shortages of Products that are complained about in good time, Niander has the choice between subsequent delivery or the corresponding credit.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Limitation of Liability
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Claims for damages by Buyer due to delay or impossibility of any order which Niander is
responsible are, except in the case of willful intent and gross negligence, limited in amount
to the invoice value of the number of Products that Niander has not delivered or the
delivery of which we Niander is in default. If damage has been caused by gross negligence or
willful misconduct, Niander’s liability is limited to the foreseeable damage as a result of this
breach of duty with a maximum of the value or the respective Order expressed as the
respective Price invoiced by Niander for such excluding any Taxes. Buyer´s sole remedy for
any defect Products covered by the warranty set forth herein, shall be replacement of the
respective Products or crediting of the respective (pro rata part of) the Prices, to be
determined by Niander at its sole discretion.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Warranty
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						The Products are warranted for a period of thirty (30) calendar days by the manufacturer
subject to the shorter notification period for complaints and provided that Buyer has
complied in full with any storage specifications regarding the Products as notified by
Niander in writing (including via email). The warranty period begins on the day of delivery
and is recorded on the delivery documents. Wear parts are excluded from the guarantee.
Products that can be proven to have become defective as a result of material or
manufacturing defects will be replaced free of charge, provided the defect is reported
within the guarantee period. No guarantee is given for replacement components that have
not been installed by our staff or the manufacturer or for breakage of glass, glass-ceramic,
ceramic materials, or packaging. The warranty claim immediately expires if Buyer does not
comply with its contractual obligation. For services, Niander is only liable for careful
execution. Further claims as well as causal liability, liability for consequential damage or for
any loss of income are fully excluded.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Property Rights
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						No license to any of our property rights is associated with the acquisition of the Products.
Niander expressly points out that the export of the Products can lead to infringements of
third-party industrial property rights and assumes no liability in such cases. The marking of
the brands with any trademark designation means that the marked brands are protected in
the country of origin of the manufacturer. Corresponding trademarks are also protected in
other countries in individual cases. All trademarks, service brands, labels and logos are

registered and unregistered trademarks of Niander or third parties. None of the content is
to be interpreted in such a way that it grants a right to use it, and any use of these symbols,
names and logos is prohibited. Data, e-mails and catalogue information sent by Niander, as
well as the item information displayed on the Internet, are copyrighted information that
may not be passed on to third parties, nor copied or displayed within third-party
frameworks.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Ineffectiveness of Individual Provisions
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						Should any of the provisions set forth in these GSTCs be unenforceable or invalid, this shall
not affect the validity of the other provisions and Parties shall use best efforts to replace the
respective affected provision by a valid and enforceable provision which reflects as closely
as possible the original unenforceable or invalid provision.
					</Text>
				</Box>
				<Box width="90%" margin="0px auto 2% auto">
					<Text width="80%" margin="16px auto 10px auto" font="--lead">
						Validity, Governing Law and Jurisdiction
					</Text>
					<Text width="80%" margin="0px auto 16px auto">
						By placing an order, Buyer recognizes the validity and applicability of these GSTCs and all
their terms and conditions. These GSTCs and any order concluded hereunder are governed
by and subject to the laws of the state of Colorado. The provisions of the United Nations
Convention on Contracts for the International Sale of Goods do not apply. Any and all
disputes arising from these GSTCs or any order concluded hereunder shall be exclusivity
submitted to the competent court in Colorado, USA.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.NianderFooter2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"642c970f8d8a1a00208a3a8f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});