import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/642c970f8d8a1a00208a3a91/images/Screenshot%20from%202023-04-11%2012-58-40.png?v=2023-04-11T10:58:45.756Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.NianderHeader />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Page Not Found
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				This Page was not found, please navigate to another page using the links above.
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"642c970f8d8a1a00208a3a8f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});